const els = document.querySelectorAll('.search-result-count');

function SearchResultCount(el) {
    window.addEventListener('loadMoreUpdate', onUpdate);

    function onUpdate(e) {
        const { searchResultCount } = e.data || {};
        const trigger = e.data.el;

        if (trigger) {
            const closestFilter = trigger.closest('.js-filters');

            if (closestFilter) {
                const searchrResultCountEl = closestFilter.querySelector('.search-result-count');

                if (searchrResultCountEl) {
                    searchrResultCountEl.innerText = searchResultCount || '';
                    return;
                }
            }
        }

        el.innerText = searchResultCount || '';
    }
}

for (let el of els) {
    SearchResultCount(el);
}
