const getEvent = (name) => {
    let event;

    try {
        event = new CustomEvent(name);
    } catch (e) {
        event = document.createEvent('Event');
        event.initEvent(name, true, true);
    }

    return event;
};

const Navigation = (PrimaryNav, header) => {
    const onClick = e => {
        e.preventDefault();
        const isMobileMenuActive = header.classList.contains('header--small');
        let event;
        let target;

        if (isMobileMenuActive) {
            target = document.getElementById(`subnav-${e.currentTarget.getAttribute('aria-controls')}`);
            event = getEvent('triggerSubnav');
        } else {
            target = document.querySelector('.mega-menu');
            event = getEvent('triggerMegaMenu');
            event['data'] = {
                trigger: e.currentTarget,
                current: document.getElementById(`${e.currentTarget.getAttribute('aria-controls')}`)
            };
        }

        target && target.dispatchEvent(event);
    };

    const init = () => {
        const triggers = PrimaryNav.querySelectorAll('.primary-nav__link[aria-controls]');

        for (let trigger of triggers) {
            trigger.addEventListener('click', (e) => onClick(e));
        }
    };

    init();
};

export default Navigation;
