
import React from 'react';
import CalculatorBlock from './components/calculatorBlock/CalculatorBlock';
import ContactBlock from './components/contactBlock/ContactBlock';
import ReferenceBlock from './components/referenceBlock/ReferenceBlock';

const EnergyCalculator = (props) => {

    return (
        <div className='energy-calculator-wrapper'>
            <CalculatorBlock {...props} />
            <ContactBlock {...props} />
            <ReferenceBlock {...props} />
        </div>

    );
};
import PropTypes from 'prop-types';

EnergyCalculator.ropTypes = {
    id: PropTypes.string
};

export default EnergyCalculator;
