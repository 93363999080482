import React from 'react';
import PropTypes from 'prop-types';

const Switch = (props) => {
    const [checked, setChecked] = React.useState(props.checked);

    const handleChange = () => {
        if (props.disabled) {

            props.onChange(false);
            return;
        }
        const newChecked = !checked;
        setChecked(newChecked);
        if (props.onChange) {
            props.onChange(newChecked); // Call the onChange prop with the new state
        }
    };

    if (props.disabled) {
        props.onChange(false);
    } else
        props.onChange(checked);

    return (
        <label htmlFor={props.id} className="switch energy-calculator__switch">
            <input
                type="checkbox"
                className="switch__checkbox"
                value={props.value}
                id={props.id}
                checked={props.disabled ? false : checked}
                onChange={() => { }}
                disabled={props.disabled}
            />
            <div className="switch__control" onClick={handleChange}></div>
            <span className="switch__label">{props.label}</span>
        </label>
    );
};

Switch.propTypes = {
    checked: PropTypes.bool,
    value: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default Switch;
