class Switchers {
    constructor(el) {
        this.handleSwitcherChange = this.handleSwitcherChange.bind(this);
        this.wrapper = el;
        this.rangeElement = el.closest('.js-range-slider');
        const switcherInputs = el.querySelectorAll('.switchers__input');
        switcherInputs.forEach(input => {
            input.addEventListener('click', (event) => this.handleSwitcherChange(event.target));
        });
        switcherInputs[0] && this.handleSwitcherChange(switcherInputs[0]);
    };

    handleSwitcherChange(input) {
        const steps = input.dataset.steps;
        const stepIncrements = input.dataset.stepIncrements;

        this.rangeElement.dataset.steps = steps;
        this.rangeElement.dataset.stepIncrements = stepIncrements;

        this.rangeElement.dispatchEvent(new Event('reset'));
    }
}

export default Switchers;
