import getEvent from '../../functions/getEvent';

class TopBar {
    constructor (el) {
        this.el = el;
        this.button = this.el.querySelector('.top-bar__close');
        this.onClick = this.onClick.bind(this);

        this.init();
    }

    onClick (e) {
        e.preventDefault();
        this.button.removeEventListener('click', this.onClick);
        this.hide();
    }

    hide() {
        const header = this.el.parentNode.querySelector('.header');
        this.el.classList.add('top-bar--hidden');

        // Recalculate header
        header && header.dispatchEvent(getEvent('recalculate'));
    }

    init() {
        this.button && this.button.addEventListener('click', this.onClick);
    }
};

export default TopBar;
