import React from 'react';
import PropTypes from 'prop-types';
import { GoogleMapsContext } from '../../context/GoogleMapsProvider';
import SearchField, { Geolocation } from '../search-field';

const ItemComponent = (props) => (
    <>
        <svg className="search-field-places__icon-wrapper" focusable="false">
            <use xlinkHref="#icon-map-pin"/>
        </svg>
        {props.description}
    </>
);

const SearchFieldPlaces = (props) => {
    const { autocompleteService, googleMapsApi } = React.useContext(GoogleMapsContext);
    const [searchResult, setSearchResult] = React.useState([]);

    const updateSearchResult = (e) => {
        const input = e.target.value || '';
        if (input.length > 0) {
            autocompleteService.getPlacePredictions({ input }, (places) => {
                setSearchResult(() => (
                    places
                        ? places.map((place) => ({
                            ...place,
                            value: place.description,
                            id: place.place_id
                        }))
                        : []
                ));
            });
        } else {
            // Reset statae when there's no search result
            setSearchResult(() => []);
        }
    };

    const getCords = async (e) => {
        const service = new googleMapsApi.places.PlacesService(document.createElement('div'));
        const cords = await new Promise((resolve, reject) => {
            try {
                service.getDetails({ placeId: e.place_id }, (data) => {
                    resolve(data.geometry.location);
                });
            } catch (e) {
                reject(e);
            }
        });
        props.onSelect(cords);
    };

    const onGeolocation = (latLng) => {
        props.onSelect(new googleMapsApi.LatLng(...latLng));
    };

    return (
        <>
            {googleMapsApi &&
                <SearchField
                    onChange={getCords}
                    items={searchResult}
                    onInputChange={updateSearchResult}
                    itemStyle="search-field-places__result"
                    className={props.className}
                    itemComponent={ItemComponent}
                >
                    <Geolocation onGeolocation={onGeolocation} />
                </SearchField>
            }
        </>
    );
};

SearchFieldPlaces.propTypes = {
    onChange: PropTypes.func
};

SearchFieldPlaces.defaultProps = {
    onChange: () => {}
};

export default SearchFieldPlaces;
