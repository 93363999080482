export const calculationDataLayerPush = (event, country, buildingType, constructiontype, sizeInSqm) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: event,
        calculation_country: country || '',
        calculation_building_type: buildingType || '',
        calculation_new_building: constructiontype || '',
        calculation_size_in_sqm: sizeInSqm || 0
    });

};
