import 'whatwg-fetch';
import template from 'lodash/template';
import forEach from 'lodash/forEach';
import getEvent from '../../functions/getEvent';
import scrollPage from '../scrollPage';

class LoadMore {
    constructor(el) {
        this.el = el;
        this.onClick = this.onClick.bind(this);
        this.onFiltersApplied = this.onFiltersApplied.bind(this);
        this.update = this.update.bind(this);
        this.contentContainer = document.getElementById(`${el.getAttribute('aria-controls')}`) || document.createElement('div');
        this.loaderOverlayContainer = this.el.closest('.js-loader-overlay-holder');
        this.filtersArray = [];
        this.filtersDictionary = {};
        this.sortData = {};
        this.loadMoreContainer = null;

        this.init();
    }

    init() {
        this.addEventListeners();
    }

    addEventListeners() {
        this.el.addEventListener('click', this.onClick);
        // this.el.addEventListener('livefilterfetched', this.updateHrefAttribute);
        this.el.addEventListener('filterApplied', this.onFiltersApplied);
    }

    onClick(e) {
        e.preventDefault();
        this.fetch(true, false, this.update);
    }

    onFiltersApplied(e) {
        const { data } = e;
        if (data.ariaControls === this.el.getAttribute('aria-controls')) {
            this.filtersArray = data.filtersArray;
            this.filtersDictionary = data.filtersDictionary;
            this.sortData = data.sortData;
            this.fetch(false, true, this.update);
        }
    }

    async fetch(isLoadMore, replaceResults, cb) {
        let endpoint = this.el.getAttribute('href');

        if (this.filtersArray && this.filtersArray.length > 0) {
            // add each grouped filterArray to endpoint.
            for (let i = 0; i < this.filtersArray.length; i++) {
                let filter = this.filtersArray[i];

                if (endpoint.indexOf('?') > -1) {
                    endpoint = `${endpoint}&${filter.name}=${filter.value.join('|')}`;
                } else {
                    endpoint = `${endpoint}?${filter.name}=${filter.value.join('|')}`;
                }
            }
        }

        for (let query in this.filtersDictionary) {
            if (this.filtersDictionary.hasOwnProperty(query)) {
                if (endpoint.indexOf('?') > -1) {
                    endpoint = endpoint + '&' + query + '=' + this.filtersDictionary[query];
                } else {
                    endpoint = endpoint + '?' + query + '=' + this.filtersDictionary[query];
                }
            }
        }

        // in order for backend to understand is this a new page request or a filter
        if (isLoadMore) {
            if (endpoint.indexOf('?') > -1) {
                endpoint = endpoint + '&loadMore=true';
            } else {
                endpoint = endpoint + '?loadMore=true';
            }
        }

        for (let query in this.sortData) {
            if (this.sortData.hasOwnProperty(query)) {
                if (endpoint.indexOf('?') > -1) {
                    endpoint = endpoint + '&' + query + '=' + this.sortData[query];
                } else {
                    endpoint = endpoint + '?' + query + '=' + this.sortData[query];
                }
            }
        }

        this.el.setAttribute('disabled', true);
        if (this.loaderOverlayContainer) {
            this.loaderOverlayContainer.classList.add('loading');
        }

        const res = await fetch(endpoint, {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        });
        const data = await res.json();

        this.el.removeAttribute('disabled');
        if (this.loaderOverlayContainer) {
            this.loaderOverlayContainer.classList.remove('loading');
        }
        cb(isLoadMore, replaceResults, data);
    }

    update(isLoadMore, replaceResults, data) {
        const { el } = this;
        const { results, nextPageUrl, toShowLoadMoreButton, showClearFiltersButton, subcategoriesMarkup, searchResultCount } = data;

        if (results) {
            const templateId = el.getAttribute('data-template-id');

            // If there's a template then use that
            // Else we assume raw markup
            if (templateId) {
                const resultTemplate = document.getElementById(templateId).innerHTML;
                const templateFn = template(
                    resultTemplate,
                    { 'imports': { 'forEach': forEach } }
                );
                if (replaceResults) {
                    this.contentContainer.innerHTML = templateFn({ results });
                } else {
                    this.contentContainer.insertAdjacentHTML('beforeend', templateFn({ results }));
                }
            } else {
                if (replaceResults) {
                    this.contentContainer.innerHTML = results;
                } else {
                    this.contentContainer.insertAdjacentHTML('beforeend', results);
                }
            }

            // We push an event for other components to hook into
            // eg if we need to reinitiate some JS after loading
            const event = getEvent('loadMoreUpdate');
            data['el'] = this.el; // Add reference to load more button in case we have more then one button on a page.
            event['data'] = data;
            window.dispatchEvent(event);

            // Update filter dropdowns markup if needed
            if (subcategoriesMarkup && subcategoriesMarkup.length > 0) {
                const filtersEvent = getEvent('updateFilterDropdowns');
                filtersEvent['data'] = data;
                this.el.dispatchEvent(filtersEvent);
            }
            } else if (data.showUseFiltersMessage) {
                const resultTemplate = document.getElementById('filters-message-data-tmpl').innerHTML;
                this.contentContainer.innerHTML = resultTemplate;
            } else if (!searchResultCount) {
                const resultTemplate = document.getElementById('no-results-data-tmpl').innerHTML;
                this.contentContainer.innerHTML = resultTemplate;
                document.querySelectorAll('.search-result-count').forEach((result) => {
                    result.innerHTML = '';
                });
            }

        if (toShowLoadMoreButton) {
            el.classList.remove('h-hidden-from-view');
            el.setAttribute('href', nextPageUrl);
        } else {
            el.classList.add('h-hidden-from-view');
        }

        const filtersHolder = el.closest('.js-filters');
        if (filtersHolder) {
            const clearFiltersHolder = filtersHolder.querySelector('.js-clear-filters-holder');
            if (clearFiltersHolder) {
                clearFiltersHolder.classList.toggle('h-hidden-from-view', !showClearFiltersButton);
            }
        }

        isLoadMore && scrollPage(window.pageYOffset + 200);
    }
}

export default LoadMore;
