import scrollPage from '../../functions/scrollPage';
import getElementPosition from '../../functions/getElementPosition';

const ExpandedRteTrigger = (el) => {
    const expandedPanel = el;
    const expandedPanelBtn = document.getElementById(el.id + '-trigger');

    const init = () => {
        expandedPanel.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'height' && expandedPanel.clientHeight > 0) {
                scrollPage(getElementPosition(expandedPanel));
            }
        });
        expandedPanelBtn.addEventListener('click', () => {
            if (expandedPanel.style.height === '') {
                expandedPanel.style.height = '100%';
                expandedPanelBtn.textContent = expandedPanelBtn.dataset.showLess;
                setTimeout(expandedPanel.scrollTo());
            } else {
                expandedPanel.style.height = '';
                expandedPanelBtn.textContent = expandedPanelBtn.dataset.showMore;
            }
        });
    };

    init();
};

export default ExpandedRteTrigger;
