import React from 'react';
import Lottie from 'lottie-react';
import animation1 from '../../../../assets/swegon-1.json';
import animation2 from '../../../../assets/swegon-2.json';
import animation3 from '../../../../assets/swegon-3.json';

const LottieAnimation = () => {

    return (
        <div className='lottie-wrapper'>
            <Lottie animationData={animation1} />
            <Lottie animationData={animation2} />
            <Lottie animationData={animation3} />
        </div>
    );
};

export default LottieAnimation;
