import globeIcon from '../../../assets/icons/globe.svg';
import mailIcon from '../../../assets/icons/mail.svg';
import mapPinIcon from '../../../assets/icons/map-pin.svg';
import mobileIcon from '../../../assets/icons/mobile.svg';
import personIcon from '../../../assets/icons/person.svg';

const setInfoWindowContent = (dealer) => {
    const {
        Name,
        StreetAdress,
        StreetAdress2,
        State,
        Country,
        PhoneNumber,
        WebsiteUrl,
        Contacts,
        Email,
        Email2,
        City,
        ZipCode
    } = dealer;

    let row1 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${mapPinIcon}" /></span><span class="dealer-address">
        ${StreetAdress ? StreetAdress + '<br />' : ''} 
        ${StreetAdress2 ? StreetAdress2 + '<br />' : ''} 
        ${(ZipCode || City) ? ZipCode + ' ' + City + '<br /> ' : ''} 
        ${State ? State + (Country ? ', ' : '') : ''} ${Country} </span> </p>`;
    let row2 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${mobileIcon}" /></span><span class="dealer-tel"><a class="google-map-info-window__link" href="tel:${PhoneNumber}">${PhoneNumber}</a> </span></p>`;
    let row3 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${globeIcon}" /></span><a class="google-map-info-window__link" href="http://${WebsiteUrl}" target="_blank">${WebsiteUrl}</a></p>`;
    let row4 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${personIcon}" /></span><span class="dealer-contact">${Contacts}</span> </p>`;
    let row5 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${mailIcon}" /></span><span class="dealer-email"><a class="google-map-info-window__link" href="mailto:${Email}">${Email}</a></span> </p>`;
    let row6 = `<p class="google-map-info-window__detail"><span class="google-map-info-window__icon"> <img src="${mailIcon}" /></span><span class="dealer-email"><a class="google-map-info-window__link" href="mailto:${Email2}">${Email2}</a></span> </p>`;

    // if (!StreetAdress || StreetAdress.length === 0)
    //     row1 = '';
    if (!PhoneNumber || PhoneNumber.length === 0)
        row2 = '';
    if (!WebsiteUrl || WebsiteUrl.length === 0)
        row3 = '';
    if (!Contacts || Contacts.length === 0)
        row4 = '';
    if (!Email || Email.length === 0)
        row5 = '';
    if (!Email2 || Email2.length === 0)
        row6 = '';

    return `<div class="google-map-info-window">
        <h5 class="google-map-info-window__title">${Name}</h5>
        ${row1}
        ${row2}
        ${row3}
        ${row4}
        ${row5}
        ${row6}
    </div>`;
};

export default setInfoWindowContent;
