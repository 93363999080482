import React from 'react';
import Icon from '../../icon';
import PropTypes from 'prop-types';
import CalculatorTooltip from './calculatorBlock/components/ToolTip/CalculatorTooltip';

const Accordion = (props) => {
    const [open, setOpen] = React.useState(props.open || false);

    return (
        <div className="accordion">
            <button className="accordion__button" aria-controls="accordion-calculation-setitings" aria-expanded={open} onClick={() => setOpen(!open)}>
                <div className='accordion-tooltip '>
                    <span>
                        {props.label}
                    </span>
                    {props.tooltipContent &&
                        <CalculatorTooltip content={props.tooltipContent} />
                    }
                </div>
                <svg className={`icon accordion__icon ${props.level >= 2 && 'accordion__icon-inner'}`} focusable="false">
                    <Icon icon="chevron-down" additionalClasses="calculator-result__item--icon"></Icon>
                </svg>
            </button>

            {/* {open && */}
            <div className={`accordion__content ${props.level && 'accordion__content-level-' + props.level}`} id="accordion-calculation-setitings" aria-hidden={!open} aria-labelledby="accordion-calculation-setitings">
                {props.children}
            </div>
            {/* } */}
        </div>
    );
};

Accordion.propTypes = {
    label: PropTypes.string,
    tooltipContent: PropTypes.string,
    level: PropTypes.number,
    children: PropTypes.any,
    open: PropTypes.bool
};

export default Accordion;
