import React, { useRef } from 'react';

const ReferenceBlock = (props) => {

    const reference = props.mainContentArea.value[0];

    const ref = useRef();

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    ref.current.classList.add('energy-calculator-reference-block--animation');
                    observer.unobserve(ref.current); // Optional: Stop observing after adding the className
                }
            },
            { threshold: 0.1 } // Trigger when 10% of the element is in view
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const ReferenceBlockCard = (props) => {

        return (
            <div className="energy-calculator-reference-block__card">
                <div className="energy-calculator-reference-block-card-wrapper">
                    <a href={props.url}>
                        {props.img ? <img src={props.img} alt={props.alt} /> : <></>}
                    </a>
                </div>
                <h3>{props.title}</h3>
                <p>{props.place}</p>
            </div>
        );
    };

    return (
        <div ref={ref} className="energy-calculator-reference-block">
            <h2>{reference.heading}</h2>
            <div>
                {reference.references.value.map((item, i) => {
                    const hasHero = item && item.mainContentArea && item.mainContentArea.value && item.mainContentArea.value[0].contentType.includes('HeroBlock');
                    const url = hasHero && item && item.mainContentArea.value[0].backgroundImage && item.mainContentArea.value[0].backgroundImage.url ? item.mainContentArea.value[0].backgroundImage.url : null;
                    const name = item.name.split(',').slice(0, 1);
                    const place = item.name.split(',').slice(1);
                    return <ReferenceBlockCard key={item.name + i} title={name} url={props.endpoint + item.url} place={place} img={props.endpoint + url} alt={item.heroHeading} delay={i === 0 ? '0' : i === 1 ? '.3s' : '.7s'} />;
                }
                )}
            </div>
        </div>
    );
};

export default ReferenceBlock;
