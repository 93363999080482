import React from 'react';
import Icon from '../../../icon';

const ContactBlock = (props) => {

    const [contactPersonIndex, setContactPersonIndex] = React.useState(null);

    const [formReady, setFormReady] = React.useState(false);

    const ref = React.useRef(null);

    React.useEffect(() => {
        // Ensure the effect runs after the component is mounted and ref is attached
        if (!ref.current) return;

        const formElement = ref.current; // Parent element
        const zipCodeElement = formElement.querySelector('.hs_zip'); // Find all <select> inside the parent
        const zipCodeInput = zipCodeElement && zipCodeElement.querySelector('input');

        const handleInput = () => {
            const value = zipCodeInput.value;
            const digitCount = value.replace(/\D/g, '').length; // Count only digits

            if (digitCount >= 5) {
                const index = props.contactPersons.value.findIndex(x => x.zipCodeFrom < value && x.zipCodeTo > value);
                setContactPersonIndex(index);
            } else setContactPersonIndex(-1);
        };

        zipCodeInput && zipCodeInput.addEventListener('input', handleInput
        );
        return () => {
            zipCodeInput && zipCodeInput.removeEventListener('input', () => handleInput);
        };

    }, [formReady]);

    React.useEffect(() => {
        // Function to load the form
        const loadForm = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    css: '',
                    portalId: '3433011',
                    formId: props.hubSpotScript,
                    target: '#hubspot_form_footprint',
                    onFormReady: function () {
                        setFormReady(true);
                    }
                });
            } else {
                console.error('HubSpot script is not loaded');
            }
        };

        // Add an event listener to run the form creation after the DOM is fully loaded
        document.addEventListener('DOMContentLoaded', loadForm);

        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('DOMContentLoaded', loadForm);
        };
    }, []);
    return (<>
        {/* Contact */}
        <div className="energy-calculator-contact-block">
            <div className='energy-calculator-contact-block__banner'>
                <div>
                    <h2>{props.contactHeading}</h2>
                    <p>{props.contactSubHeading}</p>
                </div>
            </div>
            <div className='energy-calculator-contact-block__img-wrapper'><img src={props.endpoint + props.contactImage.url || '/'} /></div>
            <div ref={ref} id='hubspot_form_footprint'></div>

            {contactPersonIndex >= 0 && contactPersonIndex !== null &&
                <>
                    <div className='energy-calculator-contact-block__contact-card'>

                        <div className="contact-card contact-card--person ">
                            <div className="contact-card__body">
                                <div className="contact-card__top-img">
                                    <img src={props.endpoint + props.contactPersons.value[contactPersonIndex].profileImage || '/'} alt="Contact Page Image" className="contact-card__icon" />
                                </div>
                                <strong className="contact-card__label">{props.contactPersons.value[contactPersonIndex].name}</strong>
                                <div className="contact-card__bottom-body">
                                    <div className="contact-card__role">{props.contactPersons.value[contactPersonIndex].role}</div>
                                    {props.contactPersons.value[contactPersonIndex].productAreas &&
                                        props.contactPersons.value[contactPersonIndex].productAreas.slice(0, 3).map((productArea) => {
                                            return <div className="js-option">{productArea}</div>;

                                        })
                                    }
                                </div>
                            </div>

                            <div className="show-info js-show-info">
                                <div className="show-info__wrapper js-show-info-wrapper">
                                    <div className="show-info__content">
                                        <ul className="contact-list">
                                            {props.contactPersons.value[contactPersonIndex].city &&
                                                <li className="contact-list__item">
                                                    <Icon icon="map-pin" additionalClasses="icon contact-list__icon"></Icon>
                                                    {props.contactPersons.value[contactPersonIndex].city}
                                                </li>
                                            }
                                            {props.contactPersons.value[contactPersonIndex].phoneNumbers && props.contactPersons.value[contactPersonIndex].phoneNumbers.map((number) => {

                                                return <li className="contact-list__item">
                                                    <a href={`tel:${number}`}>
                                                        <Icon icon="phone" additionalClasses="icon contact-list__icon"></Icon>
                                                        {number}
                                                    </a>
                                                </li>;
                                            })
                                            }

                                            {props.contactPersons.value[contactPersonIndex].email &&
                                                <li className="contact-list__item">
                                                    <a href={`mailto:${props.contactPersons.value[contactPersonIndex].email}`}>
                                                        <Icon icon="mail" additionalClasses="icon contact-list__icon"></Icon>
                                                        {props.contactPersons.value[contactPersonIndex].email}
                                                    </a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="energy-calculator-contact-block__overlay"></div>
                </>

            }
        </div>

    </>
    );
};

export default ContactBlock;
