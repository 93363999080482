import React from 'react';
import PropTypes from 'prop-types';
import SearchFieldPlaces from './SearchFieldPlaces';

const StartView = (props) => {
    return (
        <div className="find-installer-start">
            <div className="find-installer-start__panel">
                <h1>{props.lang.startTitle}</h1>
                <p className="preamble">
                    {props.lang.startDescription}
                </p>
                <SearchFieldPlaces
                    onSelect={props.onSelect}
                    className="search-field--big-m h-no-margin-bottom"
                />
            </div>
        </div>
    );
};

StartView.propTypes = {
    onSelect: PropTypes.func
};

StartView.defaultProps = {
    onSelect: () => {}
};

export default StartView;
