const ShowInfo = (el) => {
    const showInfoContent = el.querySelector('.js-show-info-wrapper');
    const showInfoBtnHolder = el.querySelector('.js-show-info-btn-holder');
    const showInfoBtn = showInfoBtnHolder.querySelector('.js-show-info-btn');
    const openText = showInfoBtnHolder.querySelector(
        '.show-info__btn-opened-text'
    );
    const hideText = showInfoBtnHolder.querySelector(
        '.show-info__btn-closed-text'
    );
    const icon = showInfoBtnHolder.querySelector('.show-info__btn-icon');
    const triggers = document.querySelectorAll(
        '[aria-controls="' + el.id + '"]'
    );

    const toggleShowInfo = () => {
        const isOpen = showInfoContent.style.maxHeight !== '0px';
        showInfoContent.style.maxHeight = isOpen
            ? 0
            : `${showInfoContent.scrollHeight}px`;
        openText.style.display = isOpen ? 'block' : 'none';
        hideText.style.display = isOpen ? 'none' : 'block';
        icon.classList.toggle('active');
        showInfoContent.closest('.show-info').classList.toggle('open');
    };

    const init = () => {
        showInfoContent.style.maxHeight = 0;
        hideText.style.display = 'none';

        triggers.forEach((trigger) => {
            trigger.addEventListener('click', toggleShowInfo);
        });

        showInfoBtn.addEventListener('click', toggleShowInfo);
    };
    el.classList.remove('js-show-info');

    init();
};

export default ShowInfo;
