import React from 'react';
import { CalculationContext } from '../../context/CalculationContext';

import AdditionalSettings from './components/AdditionalSettings/AdditionalSettings';
import Result from './components/Result/Result';
import CalculatorHeroBlock from './components/CalculatorHeroBlock/CalculatorHeroBlock';

const CalculatorBlock = (props) => {

    const [downloadPdfDisabled, setDownloadPdfDisabled] = React.useState(true);
    const [startCount, setStartCount] = React.useState(false);

    const calculationContext = React.useContext(CalculationContext);
    calculationContext.setLang(props.language.name);
    calculationContext.setEndpoint(props.endpoint);

    const handleDialogPopup = (version) => {
        let dialog;
        switch (version) {
            case 'dowloadPdf':
                dialog = document.querySelector('.calculator-result__dialog-pdf');
                break;

            case 'additionalSettings':
                dialog = document.querySelector('.calculator-result__dialog-additional-settings');
                break;

            case 'result':
                setStartCount(!startCount);
                dialog = document.querySelector('.calculator__result-dialog');
                dialog.addEventListener('keydown', function (event) {
                    if (event.key === 'Escape') {
                        event.preventDefault(); // Prevent the dialog from closing
                    }
                });
                break;

            default:
                break;
        }
        if (dialog.hasAttribute('open'))
            dialog.close();
        else
            dialog.showModal();

    };

    React.useEffect(() => {

        const initialResponse = {
            'country': props.countries[0].value.country,
            'application': props.applications[0].value.application,
            'newBuilding': true,
            'sizeOfBuilding': props.defaultBuildingSize,
            'energyUsePerSquareMeter': props.countries[0].value.estimatedEnergyUsageNewBuilding,
            'heatingType': props.heatingTypes[0].value.name,
            'heatingProductionEfficiency': props.heatingTypes[0].value.efficiencyFactor,
            'hotwaterProduction': props.hotwaterTypes[0].value.name,
            'hotwaterProductionEfficiency': props.hotwaterTypes[0].value.efficiencyFactor,
            'coolingProduction': props.coolingTypes[0].value.name,
            'coolingProductionEfficiency': props.coolingTypes[0].value.efficiencyFactor,
            'energyUseSqMetersExisting': props.countries[0].value.estimatedEnergyUsageExistingBuilding,
            'currency': props.countries[0].value.currency

        };

        calculationContext.setCountry(initialResponse.country);
        calculationContext.setApplication(initialResponse.application);
        calculationContext.setHeatingType(initialResponse.heatingType);
        calculationContext.setHotWaterProduction(initialResponse.hotwaterProduction);
        calculationContext.setCoolingProduction(initialResponse.coolingProduction);

        calculationContext.setSizeOfBuilding(initialResponse.sizeOfBuilding);
        calculationContext.setNewBuilding(initialResponse.newBuilding);
        calculationContext.setEnergyUsePerSquareMeter(initialResponse.energyUsePerSquareMeter);
        calculationContext.setHeatingProductionEfficiency(initialResponse.heatingProductionEfficiency);
        calculationContext.setHotWaterProductionEfficiency(initialResponse.hotwaterProductionEfficiency);
        calculationContext.setCoolingProductionEfficiency(initialResponse.coolingProductionEfficiency);

        calculationContext.setEnergyUseSqMetersNew(initialResponse.energyUsePerSquareMeter);
        calculationContext.setEnergyUseSqMetersExisting(initialResponse.energyUseSqMetersExisting);

        calculationContext.setCurrency(initialResponse.currency);

    }, []);

    return (
        <> {/* Calculator */}
            <div className='calculator-wrapper'>
                <CalculatorHeroBlock props={props} setDownloadPdfDisabled={setDownloadPdfDisabled} handleDialogPopup={handleDialogPopup} />

                {/* Result */}
                <Result
                    translations={props.translations}
                    startCount={startCount}
                    handleDialogPopup={handleDialogPopup}
                    downloadPdfDisabled={downloadPdfDisabled}
                    calculateDisclaimer={props.calculateDisclaimer}
                    heading={props.downloadPdfHeading}
                    preamble={props.downloadPdfPreamble}
                />

                {/* Additional Settings */}
                <AdditionalSettings
                    handleDialogPopup={handleDialogPopup}
                    {...props}
                />
            </div >

        </>
    );
};

export default CalculatorBlock;
