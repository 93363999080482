import { gsap } from 'gsap';
import isInViewport from '../../functions/isInViewport';

const Tabs = (el) => {
    const panels = el.querySelectorAll('.tabs__content-item');
    const buttons = el.querySelectorAll('.tabs__button');

    const open = (activeId) => {
        const activePanel = document.getElementById(activeId);
        if (!activePanel && panels[0]) {
            open(panels[0].id);
            return;
        }
        const activeButtons = [...buttons].filter((button) => button.getAttribute('aria-controls') === activeId);
        let visibleActiveButton = null;

        if (el.classList.contains('tabs--with-anchors')) {
            history.pushState(null, null, '#' + activeId);
        }

        for (let button of activeButtons) {
            button.setAttribute('aria-expanded', true);
            visibleActiveButton = button;
        }

        activePanel.setAttribute('aria-hidden', false);
        el.classList.add('tabs--open');

        const dataParams = localStorage.getItem('data-params');

        if (dataParams) {
            dataParams.substring(1).split('&').map(item => {
                const param = item.split('=');
                const element = document.getElementById(param[0]);
                if (element) {
                    element.value = param[1];
                    const event = new Event('change', { bubbles:true });
                    element.dispatchEvent(event);
                }
            });
            localStorage.removeItem('data-params');
        }

        if (visibleActiveButton && !isInViewport(activePanel)) {
            gsap.to(window, { duration: 0.3, scrollTo: visibleActiveButton });
        };
    };

    const toggle = (activeId) => {
        resetTabs();
        open(activeId);
    };

    const resetTabs = () => {
        for (let panel of panels) {
            panel.setAttribute('aria-hidden', true);
        }

        for (let button of buttons) {
            button.setAttribute('aria-expanded', false);
        }
    };

    const initialiseEvents = () => {
        for (let button of buttons) {
            button.addEventListener('click', (e) => {
                const id = e.currentTarget.getAttribute('aria-controls');
                toggle(id);
            });
        }
    };

    const changeTabs = () => {
        resetTabs();
        open(location.hash.replace('#', ''));
    };

    const init = () => {
        initialiseEvents();
        if (el.classList.contains('tabs--with-anchors')) {
            if (location.hash && document.getElementById(location.hash.replace('#', ''))) {
                changeTabs();
            }
            window.addEventListener('hashchange', (event) => {
                const tabId = location.hash.replace('#', '');
                if (document.getElementById(tabId)) {
                    changeTabs();
                }
            });
        }
    };

    init();
};

export default Tabs;
