import * as turf from '@turf/turf';

export const installersToContactInfoProps = (installers, selectedPoint) => {
    const from = turf.point([selectedPoint.lng(), selectedPoint.lat()]);
    const options = { units: 'miles' };
    installers.sort((a, b) => {
        const toA = turf.point([a.Longitude, a.Latitude]);
        const toB = turf.point([b.Longitude, b.Latitude]);

        return turf.distance(from, toA, options) - turf.distance(from, toB);
    });
    const getIcon = (key) =>
        ({
            StreetAdress: 'map-pin',
            PhoneNumber: 'mobile',
            WebsiteUrl: 'globe',
            Contacts: 'person',
            Email: 'mail',
            Email2: 'mail'
        }[key]);

    const getHref = (key, val) =>
        ({
            PhoneNumber: `tel:${val}`,
            WebsiteUrl: `http://${val}`,
            Email: `mailto:${val}`,
            Email2: `mailto:${val}`
        }[key]);

    return installers.reduce((acc, installer) => {
        const {
            StreetAdress,
            StreetAdress2,
            State,
            ZipCode,
            City,
            Country,
            PhoneNumber,
            WebsiteUrl,
            Contacts,
            Email,
            Email2
        } = installer;

        const AddressLine3 = (ZipCode || '') + (ZipCode && ' ') + (City || '');
        const AddressLine4 = (State || '') + (State && ', ') + (Country || '');

        const selectedFields = {
            StreetAdress,
            StreetAdress2,
            AddressLine3,
            AddressLine4,
            PhoneNumber,
            WebsiteUrl,
            Contacts,
            Email,
            Email2
        };

        return [
            ...acc,
            {
                key: installer.Id,
                title: installer.Name,
                contactInfo: [
                    ...Object.keys(selectedFields).reduce((acc, key) => {
                        const val = selectedFields[key];
                        const href = getHref(key, val);
                        if (val !== '' && !!val) {
                            return [
                                ...acc,
                                val !== '' && {
                                    description: val,
                                    icon: getIcon(key),
                                    ...(href && { href })
                                }
                            ];
                        }
                        return acc;
                    }, [])
                ]
            }
        ];
    }, []);
};
