import HorizontalScroller from './HorizontalScroller';
const els = document.querySelectorAll('.horizontal-scroller');

for (let el of els) {
    const showScrollIcon = (el.getAttribute('data-show-scroll-icon') === 'true');

    new HorizontalScroller(el, {
        showScrollIcon
    });
}
