import CustomScroller from './CustomScroller';

const els = document.querySelectorAll('.js-custom-scroller');

for (let el of els) {
    new CustomScroller(el, {
        minBarSize: 50,
        barType: 'default', // or progress
        alwaysShowBars: true,
        horizontalMouseScroll: false,
        navButtons: false,
        scrollAmount: 10
    });
}
