import Header from './Header';
import Navigation from './Navigation';

const els = document.querySelectorAll('.header');

for (let el of els) {
    const PrimaryNav = el.querySelector('.primary-nav');

    Header(el);

    if (PrimaryNav) {
        Navigation(PrimaryNav, el);
    }
}
