const Playlist = (el, cb) => {
    const items = el.querySelectorAll('.playlist__item');
    const buttons = [...items].reduce((acc, item) => {
        const btnGroup = Array.prototype.slice.call(document.querySelectorAll(`[aria-controls="${item.id}"]`));
        return [
            ...acc,
            ...btnGroup
        ];
    }, []);

    const onClick = (e) => {
        const activeItemId = e.currentTarget.getAttribute('aria-controls');
        const activeItem = document.getElementById(activeItemId);

        if (activeItem.getAttribute('aria-selected') !== 'true') {
            clearPlaylist();
            activeItem.setAttribute('aria-selected', true);
        }

        cb(activeItem);
    };

    const clearPlaylist = () => {
        for (let button of buttons) {
            const parent = button.parentNode;
            parent.setAttribute('aria-selected', false);
        }
    };

    const init = () => {
        // Add click event to all buttons with playlist item id.
        for (let button of buttons) {
            button.addEventListener('click', onClick);
        }
    };

    init();
};

export default Playlist;
