function Header(header) {
    // elements
    const _modifierClasses = ['header--small', 'header--medium', 'header--large'];
    const primaryNav = header.querySelector('.primary-nav');
    const primaryNavnavInner = primaryNav.querySelector('.primary-nav__inner');
    const primaryNavContainer = header.querySelector('.header__primary-nav');
    const menuButton = header.querySelector('.menu-button');
    const body = document.body;
    const openSearchButton = header.querySelector('.open-search-button');
    const searchOverlay = header.querySelector('.header__overlay');

    // css classes
    const navigationOpenModifier = 'header__primary-nav--open';
    const mobileButtonOpenModifier = 'menu-button--open';
    const searchOpenModifier = 'header--search-open';
    const overFlowHidden = 'h-overflow-hidden';

    const escKeyCode = 27;
    let isSearchOpen = false;

    // listeners
    openSearchButton && openSearchButton.addEventListener('click', toggleSearch);
    searchOverlay && searchOverlay.addEventListener('click', toggleSearch);
    document.addEventListener('keydown', (e) => e.keyCode === escKeyCode && closeSearch());

    function clearAndAddBaseClasses() {
        let classListWithoutModifiers = [...header.classList].filter((className) =>
        !_modifierClasses.includes(className));
        header.className = '';
        header.classList.add(...classListWithoutModifiers);
    }

    // function for applying a modifier class which works with the current view port width
    function addModifier(modifierClasses) {
        if (primaryNav.clientWidth < primaryNavnavInner.scrollWidth) {
            const currentModifier = modifierClasses[modifierClasses.length - 1];
            clearAndAddBaseClasses();
            header.classList.add(currentModifier);
            if (modifierClasses.length === 1) {
                return;
            }
            addModifier(_modifierClasses.slice(0, modifierClasses.length - 1));
        }
    }

    function toggleMobileMenu () {
        const blogPageHeaderWrapper = document.querySelector('.header-container-wrapper') || document.createElement('div');
        const topBarHeight = (document.querySelector('.top-bar') && document.querySelector('.top-bar').offsetHeight) || 0;
        const headerMainNavWrapper = primaryNavContainer.parentNode;
        const newMainNavWrapperHeight = window.innerHeight - topBarHeight;

        if (primaryNavContainer.classList.contains(navigationOpenModifier) && menuButton.classList.contains(mobileButtonOpenModifier)) {
            primaryNavContainer.classList.remove(navigationOpenModifier);
            menuButton.classList.remove(mobileButtonOpenModifier);
            blogPageHeaderWrapper.style.height = 'auto';
            headerMainNavWrapper.style.height = 'auto';
            body.classList.remove(overFlowHidden);
            header.classList.remove('header--mobile-menu-open');
        } else {
            primaryNavContainer.classList.add(navigationOpenModifier);
            menuButton.classList.add(mobileButtonOpenModifier);
            blogPageHeaderWrapper.style.height = '100%';
            headerMainNavWrapper.style.height = `${newMainNavWrapperHeight}px`;
            body.classList.add(overFlowHidden);
            header.classList.add('header--mobile-menu-open');
            animateHeader();
        }
        window.scrollTo(0, 0);

        if (!primaryNavContainer.classList.contains(navigationOpenModifier) && isSearchOpen) {
            toggleSearch();
        }
    }

    function recalculateMobileMenu() {
        // Check if mobile menu is open, then calculate.
        if (primaryNavContainer.classList.contains(navigationOpenModifier) && menuButton.classList.contains(mobileButtonOpenModifier)) {
            const topBarHeight = (document.querySelector('.top-bar') && document.querySelector('.top-bar').offsetHeight) || 0;
            const headerMainNavWrapper = primaryNavContainer.parentNode;
            const newMainNavWrapperHeight = window.innerHeight - topBarHeight;

            headerMainNavWrapper.style.height = `${newMainNavWrapperHeight}px`;
            primaryNavContainer.scrollTop = 0;
        }
    }

    function animateHeader () {
        header.classList.add('header--mobile-menu-animate');

        setTimeout(() => {
            header.classList.remove('header--mobile-menu-animate');
            primaryNavContainer.scrollTop = 0;
        }, 310);
    }

    function closeSearch () {
        const searchField = header.querySelector('.search-field input');
        isSearchOpen = false;
        openSearchButton.setAttribute('aria-expanded', isSearchOpen);

        header.classList.remove(searchOpenModifier);

        if (searchField) {
            !isSearchOpen && searchField.blur();
        }
    }

    function toggleSearch () {
        const searchField = header.querySelector('.search-field input');
        isSearchOpen = !JSON.parse(openSearchButton.getAttribute('aria-expanded'));
        openSearchButton.setAttribute('aria-expanded', JSON.stringify(isSearchOpen));

        header.classList.toggle(searchOpenModifier, isSearchOpen);

        if (searchField) {
            isSearchOpen
                ? searchField.focus() : searchField.blur();
        }
    }

    // initial check
    addModifier(_modifierClasses);

    // listiners
    window.addEventListener('resize', () => addModifier(_modifierClasses));
    menuButton.addEventListener('click', toggleMobileMenu);
    header.addEventListener('recalculate', recalculateMobileMenu);
}

export default Header;
