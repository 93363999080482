import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon';

const MapContactCard = (props) => (
    <div
        className={`map-contact-card
        ${props.active && 'map-contact-card--active'}`}
        onClick={(e) => {
            if (e.currentTarget !== e.target) {
                return;
            }
            props.onClick();
        }}
    >
        <h5>{props.title}</h5>
        <address className="map-contact-card__info">
            <ul className="map-contact-card__info-list">
                {props.contactInfo.map((info, i) => {
                    return <li className={`map-contact-card__info-item ${!info.icon && 'h-no-margin-top'}`} key={`item-${props.id}-${i}`}>
                        {React.createElement(info.href ? 'a' : 'span', { href: info.href },
                            <>
                                <Icon icon={info.icon} additionalClasses="map-contact-card__icon" />
                                {info.description}
                            </>
                        )}
                    </li>;
                    }
                )}
            </ul>
        </address>
    </div>
);

MapContactCard.propTypes = {
    id: PropTypes.string.isRequired,
    active: PropTypes.bool,
    contactInfo: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        icon: PropTypes.string,
        href: PropTypes.string
    }))
};

export default MapContactCard;
