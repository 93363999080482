const Subnav = el => {
    const header = document.querySelector('.header');
    const headerSubmenuOpenClass = 'header--subnav-open';
    const headerSubnavAnimateClass = 'header--subnav-animate';
    const activeSubnavClass = 'subnav__list-item--active';
    const submenuToggleClass = 'subnav--submenu-open';

    const onClick = e => {
        e.preventDefault();
        const currentMenu = document.getElementById(e.currentTarget.getAttribute('aria-controls'));
        open(currentMenu, el);
    };

    const open = (currentMenu, el) => {
        el.classList.add(submenuToggleClass);
        currentMenu.parentNode.classList.add(activeSubnavClass);
        currentMenu.setAttribute('aria-hidden', false);
        animateSubnav('left');
    };

    const close = (el, currentMenu) => {
        el.classList.remove(submenuToggleClass);
        currentMenu.parentNode.classList.remove(activeSubnavClass);
        currentMenu.setAttribute('aria-hidden', true);
        animateSubnav('right');
    };

    const showSubnav = e => {
        el.setAttribute('aria-hidden', false);
        header && header.classList.add(headerSubmenuOpenClass);
        animateSubnav('left');
    };

    const hideSubnav = () => {
        el.setAttribute('aria-hidden', true);
        header && header.classList.remove(headerSubmenuOpenClass);
        animateSubnav('right');
    };

    const animateSubnav = (direction) => {
        header.classList.add(`${headerSubnavAnimateClass}-${direction}`);

        setTimeout(() => {
            header.classList.add(headerSubnavAnimateClass);

            setTimeout(() => {
                header.classList.remove(`${headerSubnavAnimateClass}-${direction}`);

                setTimeout(() => {
                    header.classList.remove(headerSubnavAnimateClass);
                    header.querySelector('.header__primary-nav').scrollTop = 0;
                }, 300);
            }, 10);
        }, 10);
    };

    const init = el => {
        el.addEventListener('triggerSubnav', showSubnav);
        el.querySelector('.subnav__button').addEventListener('click', hideSubnav);

        const triggers = el.querySelectorAll('.link[aria-controls]');
        for (let trigger of triggers) {
            trigger.addEventListener('click', onClick);
        }

        const backButtons = el.querySelectorAll('.subnav__button');
        for (let button of backButtons) {
            button.addEventListener('click', () => close(el, button.parentNode));
        }
    };

    init(el);
};

export default Subnav;
