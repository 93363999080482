import { gsap } from 'gsap';
import isInViewport from '../../functions/isInViewport';
import isHidden from '../../functions/isHidden';

const Tabs = (el) => {
    let panelIsOpen = null;
    const panels = el.querySelectorAll('.interactive-image-tabs__content-item');
    const buttons = [...panels].reduce((acc, panel) => {
        const btnGroup = Array.prototype.slice.call(document.querySelectorAll(`[aria-controls="${panel.id}"]`));
        return [
            ...acc,
            ...btnGroup
        ];
    }, []);

    const open = (activeId) => {
        const activePanel = document.getElementById(activeId);
        const activeButtons = [...buttons].filter((button) => button.getAttribute('aria-controls') === activeId);
        let visibleActiveButton = null;

        for (let button of activeButtons) {
            button.setAttribute('aria-expanded', true);

            if (!isHidden(button) && button.getAttribute('role') === 'tab') {
                visibleActiveButton = button;
            }
        }

        activePanel.setAttribute('aria-hidden', false);
        el.classList.add('interactive-image-tabs--open');
        panelIsOpen = activePanel;

        if (visibleActiveButton && !isInViewport(activePanel)) {
            gsap.to(window, { duration: 0.3, scrollTo: visibleActiveButton });
        };
    };

    const close = () => {
        panelIsOpen = null;
        el.classList.remove('interactive-image-tabs--open');
    };

    const toggle = (activeId) => {
        resetTabs();

        if (!panelIsOpen) {
            open(activeId);
        } else {
            if (panelIsOpen.getAttribute('id') === activeId) {
                close();
            } else {
                open(activeId);
            }
        }
    };

    const resetTabs = () => {
        for (let panel of panels) {
            panel.setAttribute('aria-hidden', true);
        }

        for (let button of buttons) {
            button.setAttribute('aria-expanded', false);
        }
    };

    const addEventListeners = () => {
        for (let button of buttons) {
            button.addEventListener('click', (e) => {
                const id = e.currentTarget.getAttribute('aria-controls');
                toggle(id);
            });
        }
    };

    const init = () => {
        addEventListeners();
    };

    init();
};

export default Tabs;
