import ShowInfo from './ShowInfo';

const els = document.querySelectorAll('.js-show-info');

for (let el of els) {
    ShowInfo(el);
}

window.addEventListener('click', (event) => {
    const link = event.target.closest('a');
    if (link) {
        const data = link.dataset.params;
        localStorage.setItem('data-params', data);
    }
});
